import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import useMediaQuery from '@hooks/useMediaQuery';

import ContactsListSendMoney from '@components/ContactsListSendMoney/ContactsListSendMoney';
import ContactInformationWrap from '@components/transaction/ContactInformation/ContactInformation';

import { Container, Wrap } from './SendMoney.styles';

const SendMoney = () => {
  const { breakpoints } = useTheme();
  const isLaptop = useMediaQuery(`(${breakpoints.laptop})`);
  const setScrolled = useBoundStore((state) => state.setScrolled);
  const selectedContact = useBoundStore((state) => state.selectedContact);
  const navigate = useNavigate();

  if (!selectedContact) navigate('/send-money');

  useEffect(() => {
    setScrolled(false);
  }, [setScrolled]);

  useEffect(() => {
    if (!selectedContact) {
      if (!isLaptop) {
        navigate('/send-money/transaction-form');
      } else {
        navigate('/send-money');
      }
    }
  }, [selectedContact]);

  return (
    <Container>
      <Wrap>
        <ContactsListSendMoney />
      </Wrap>
      <ContactInformationWrap />
    </Container>
  );
};

export default SendMoney;
