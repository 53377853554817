import React from 'react';
import { Outlet } from 'react-router-dom';

import CartPage from '../../components/Cards/CartPage';
import CartsLeftSide from '../../components/Cards/CartsLeftSide';
import { Container } from './Cards.styles';

const Cards = () => {
  return (
    <Container>
      <CartsLeftSide />
      <CartPage />
    </Container>
  );
};

export default Cards;
