import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import useMediaQuery from '@hooks/useMediaQuery';

import Avatar from '@components/Avatar/Avatar';

import { ContainerSmall, List, NameSmall } from './Contact.styles';

interface Props {
  contact: ContactsWithBankDetails;
  className?: string;
  selected?: boolean;
  setSelected?: (contact: ContactsWithBankDetails | null) => void;
  iconVisible?: boolean;
}
const Contact = ({ contact, setSelected, selected }: Props) => {
  const navigate = useNavigate();
  const { breakpoints } = useTheme();
  const {
    name,
    // tag, createdAt
  } = contact;
  const isLaptop = useMediaQuery(`(${breakpoints.laptop})`);
  const selectedContact = useBoundStore((state) => state.selectedContact);
  const setSelectedContact = useBoundStore((state) => state.setSelectedContact);

  const handleClick = () => {
    if (setSelected) {
      if (isLaptop) {
        const newSelectedContact = selectedContact?.id === contact.id ? null : contact;
        setSelected(newSelectedContact);
      }

      if (!isLaptop) {
        setSelectedContact(contact);
        navigate('/send-money/transaction-form');
      }
    }
  };

  return (
    <ContainerSmall
      $selected={isLaptop && selected}
      onClick={handleClick}
    >
      {' '}
      <Avatar
        src=""
        alt={`Avatar ${name}`}
        text={name ?? 'A'}
        dark={true}
        size="small"
      />
      <List>
        <NameSmall>{name}</NameSmall>
        {/*{createdAt && (*/}
        {/*  <Since>In contact list since: {format(new Date(createdAt), 'dd/MM/yyyy')}</Since>*/}
        {/*)}*/}
        {/*<Tag>{!tag || tag === '' ? 'No hashtag assigned' : tag}</Tag>*/}
      </List>
      {/*{selected && (*/}
      {/*  <TriangleWrap className="triangleWrap">*/}
      {/*    <Triangle />*/}
      {/*  </TriangleWrap>*/}
      {/*)}*/}
    </ContainerSmall>
  );
};

export default Contact;
