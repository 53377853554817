import { useQueryClient } from '@tanstack/react-query';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { useEnrollDevice, useVerifyDevice } from '@api/AdditionalFactors/additionalFactorsApi';
import { useGetCorporate, useUpdateCorporate } from '@api/Corporate/corporateApi';

import OnboardingSMSAuthMobile2x from '@assets/images/ImageOnboardingSMSAuthMobile2x.png';
import OnboardingSMSAuthMobile from '@assets/images/ImageOnboardingSMSAuthMobile.png';
import OnboardingSMSAuthTablet from '@assets/images/ImageOnboardingSMSAuthTablet.png';

import { useBoundStore } from '@stores/BoundStore';

import { FormValidation } from '@shared/formValidation';

import { IconArrowLeft } from '@constants/icons';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import {
  BlueText,
  ButtonContainer,
  CloseButtonSmall,
  Container,
  CustomIconOnboardingCheck,
  CustomLargeButton,
  CustomModal,
  HeadText,
  Icon,
  IconTablet,
  InfoText,
  Label,
  MobilePhoneRow,
  NumberTextInput,
  OtpInputContainer,
  OtpInputField,
  ProblemLink,
  ProblemRow,
  SmsAuthImage,
  StyledIconLock,
  StyledIconSMS,
  SubText,
  SupportFeaturesContainer,
} from '@components/OTPModal/OTPModal.styles';

import InputPhonePrefix from '@elements/input/InputPhonePrefix/InputPhonePrefix';

const OTPModal = () => {
  const queryClient = useQueryClient();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [step, setStep] = useState(0);
  const [isOtpInputEnabled, setIsOtpInputEnabled] = useState(false);
  const [phonePrefixValid, setPhonePrefixValid] = useState<boolean>(false);
  const [phoneNumberValid, setPhoneNumberValid] = useState<boolean>(false);
  const otpInputRef = useRef<HTMLInputElement | null>(null);
  const [resetButtonCountdown, setResetButtonCountdown] = useState<number>(30);
  const [resetButtonDisabled, setResetButtonDisabled] = useState<boolean>(true);

  const corporateData = useBoundStore((state) => state.corporateData);
  const setCorporateData = useBoundStore((state) => state.setCorporateData);
  const closeModal = useBoundStore((state) => state.closeModal);

  const { handleHTTPErrors } = useHTTPErrorHandler();

  const { data: corporate, isError, error } = useGetCorporate();

  const { mutate: updateMutation } = useUpdateCorporate();
  const { mutate: enrollDeviceMutation } = useEnrollDevice();
  const { mutate: verifyDeviceEnrollmentMutation } = useVerifyDevice();

  const handleChange = (name: string, value: string) => {
    setCorporateData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleNumberSubmit = () => {
    if (submitting) return;

    setPhoneNumberValid(false);
    setSubmitting(true);

    const updateData = {
      phone: {
        phonePrefix: corporateData.phonePrefix,
        phone: corporateData.phone,
      },
    };

    updateMutation(updateData, {
      onSuccess: () => {
        setStep(1);
        sendOTP();
      },
      onError: (error) => {
        handleHTTPErrors([error]);
        setSubmitting(false);
      },
    });
  };

  const sendOTP = () => {
    if (submitting) return;

    setSubmitting(true);

    enrollDeviceMutation(undefined, {
      onSuccess: () => {
        setIsOtpInputEnabled(true);
        setSubmitting(false);
      },
      onError: (error) => {
        handleHTTPErrors([error]);
        setSubmitting(false);
      },
    });
  };

  const handleOTPSubmit = () => {
    if (submitting || !otpInputRef.current) return;

    setSubmitting(true);

    const verificationCode = otpInputRef.current.value;

    verifyDeviceEnrollmentMutation(verificationCode, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['getCorporate'],
          exact: true,
          refetchType: 'active',
        });
        closeModal();
      },
      onError: (error) => {
        handleHTTPErrors([error]);
        setSubmitting(false);
      },
    });
  };

  useEffect(() => {
    if (isError && error) {
      handleHTTPErrors([error]);
    }
  }, [error, handleHTTPErrors, isError]);

  useEffect(() => {
    let countdown: ReturnType<typeof setInterval>;
    const handleCountdown = () => {
      setResetButtonCountdown((prevState) => {
        if (prevState === 0) {
          clearInterval(countdown);
          return 0;
        } else {
          return prevState - 1;
        }
      });
    };

    if (step === 1 && resetButtonDisabled) {
      countdown = setInterval(handleCountdown, 1000);
    }

    return () => {
      clearInterval(countdown);
    };
  }, [resetButtonDisabled, step]);

  useEffect(() => {
    if (resetButtonCountdown === 0) {
      setResetButtonDisabled(false);
    }
  }, [resetButtonCountdown]);

  const handleBack = () => {
    if (step === 0 || step > 2) return closeModal();
    setStep(step === 1 && phoneNumberValid ? 2 : 0);
  };

  return (
    <CustomModal $step={step}>
      <CloseButtonSmall
        onClick={handleBack}
        $step={step}
      >
        <IconArrowLeft />
      </CloseButtonSmall>
      <Container>
        {step === 1 ? (
          <>
            <IconTablet>
              <StyledIconSMS />
            </IconTablet>
            <SmsAuthImage
              srcSet={`${OnboardingSMSAuthMobile2x} 320w, ${OnboardingSMSAuthTablet} 680w`}
              src={OnboardingSMSAuthMobile}
            />
            <HeadText $step={step}>SMS Authentication</HeadText>
            <SubText $step={step}>
              We sent a verification code to your phone number, please enter it below to verify that
              it is you.
            </SubText>
            <Label $step={step}>Enter code below</Label>
            <OtpInputContainer>
              <OtpInputField
                ref={otpInputRef}
                maxLength={6}
                placeholder="Enter code"
                disabled={!isOtpInputEnabled}
                onChange={() => {
                  if (otpInputRef.current && otpInputRef.current.value.length === 6) {
                    handleOTPSubmit();
                  }
                }}
              />
            </OtpInputContainer>
            <SupportFeaturesContainer>
              <p>Didn&apos;t get one?</p>
              <ProblemRow>
                <ProblemLink
                  disabled={resetButtonDisabled}
                  onClick={() => {
                    setResetButtonDisabled(true);
                    setResetButtonCountdown(30);
                    sendOTP();
                  }}
                >
                  {`Resend${resetButtonCountdown > 0 ? ` (${resetButtonCountdown})` : ''}`}
                </ProblemLink>
                or
                <ProblemLink
                  onClick={() => {
                    window.HubSpotConversations.widget.load();
                    window.HubSpotConversations.widget.open();
                    closeModal();
                  }}
                >
                  Contact Support
                </ProblemLink>
              </ProblemRow>
            </SupportFeaturesContainer>
          </>
        ) : step === 2 ? (
          <>
            <SmsAuthImage
              srcSet={`${OnboardingSMSAuthMobile2x} 320w, ${OnboardingSMSAuthTablet} 680w`}
              src={OnboardingSMSAuthMobile}
            />
            <HeadText $step={step}>Change a phone number</HeadText>
            <SubText $step={step}>
              To enhance the security of your account, you have to set up OTP (One-Time Password)
              verification. Please confirm your phone number to proceed.
            </SubText>
            <Label $step={step}>Change your Mobile Number</Label>
            <MobilePhoneRow>
              <InputPhonePrefix
                onChange={(event) => handleChange('phonePrefix', event.target.value)}
                setPrefixValidated={setPhonePrefixValid}
              />
              <NumberTextInput
                autoComplete="do-not-autofill"
                value={corporateData.phone}
                placeholder="123456789"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleChange('phone', event.target.value);
                  setPhoneNumberValid(
                    FormValidation.RegEx.PHONE_NUMBER.test(corporateData.phone) &&
                      corporateData.phone !== ''
                  );
                }}
                icon={corporateData.phone && phoneNumberValid && <CustomIconOnboardingCheck />}
                valid={phoneNumberValid || !corporateData.phone}
                blurHandler={() =>
                  setPhoneNumberValid(
                    FormValidation.RegEx.PHONE_NUMBER.test(corporateData.phone) &&
                      corporateData.phone !== ''
                  )
                }
              />
            </MobilePhoneRow>
            <CustomLargeButton
              disabled={!phonePrefixValid || !phoneNumberValid || submitting}
              text="Confirm & Send Code"
              variant="blueWhite"
              onClick={handleNumberSubmit}
              $step={step}
            />
          </>
        ) : (
          <>
            <Icon>
              <StyledIconLock />
            </Icon>
            <HeadText $step={step}>Secure Your Account - Enable OTP</HeadText>
            <SubText $step={step}>
              To enhance the security of your account, you have to set up OTP (One-Time Password)
              verification. Please confirm your phone number to proceed.
            </SubText>
            <InfoText>
              <span>The phone number you entered during registration: </span>
              {corporate && (
                <BlueText>
                  {corporate.mobile?.countryCode ?? '-'} {corporate.rootUser?.mobile?.number ?? '-'}
                </BlueText>
              )}
              <br />
              <br />
              Once confirmed, you&apos;ll receive a code to complete the process.
            </InfoText>
            <ButtonContainer>
              <CustomLargeButton
                text="Change"
                variant="darkWhite"
                onClick={() => setStep(2)}
                $step={step}
              />
              <CustomLargeButton
                disabled={submitting}
                text="Confirm & Send Code"
                variant="blueWhite"
                onClick={() => {
                  setStep(1);
                  sendOTP();
                }}
                $step={step}
              />
            </ButtonContainer>
          </>
        )}
      </Container>
    </CustomModal>
  );
};

export default OTPModal;
