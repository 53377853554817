import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconOTPLock, IconOnboardingCheck, IconSms } from '@constants/icons';
import { OTPModalBackground, PaymentDeleteMobile } from '@constants/images';

import Modal from '@components/Modal/Modal';

import LargeButton from '@elements/LargeButton/LargeButton';
import TextInput, { Field } from '@elements/input/TextInput/TextInput';

export const CustomModal = styled(Modal)<{ $step: number }>`
  padding: ${(props) => (props.$step === 0 ? '0 42px' : '0 20px')};
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  background: image-set(url(${PaymentDeleteMobile[0]}) 1x, url(${PaymentDeleteMobile[1]}) 2x)
    no-repeat center center / 100% auto;
  background-size: cover;
  position: relative;
  box-sizing: border-box;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0 40px;
    min-height: unset;
    min-width: 580px;
    background: image-set(url(${OTPModalBackground[0]}) 1x, url(${OTPModalBackground[1]}) 2x)
      no-repeat center center / 100% auto;
    background: ${(props) => props.$step !== 1 && props.theme.palette.primary.white};
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 380px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 93px;
  box-sizing: border-box;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: 50px;
  }
`;

export const Icon = styled.div`
  width: 76px;
  height: 76px;
  margin-bottom: 20px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.primary.white};
  box-shadow:
    8px 8px 20px 0 rgba(174, 174, 192, 0.2),
    6px 6px 15px 0 rgba(123, 123, 138, 0.08),
    -8px -8px 20px 0 #fff,
    -6px -6px 15px 0 #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconTablet = styled(Icon)`
  display: none;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
  }
`;

export const StyledIconLock = styled(IconOTPLock)`
  width: 33px;
  height: 42px;
`;

export const StyledIconSMS = styled(IconSms)`
  width: 33px;
  height: 42px;
`;

export const HeadText = styled.div<{ $step: number }>`
  ${(props) => (props.$step === 1 ? FontStyles.h3 : FontStyles.h4)};
  margin-bottom: 10px;
  background: ${({ theme }) => theme.palette.gradients.primaryBlackGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    ${(props) => props.$step === 1 && FontStyles.h4};
    margin-top: ${(props) => (props.$step === 2 ? '65px' : '0')};
  }
`;

export const SubText = styled.div<{ $step: number }>`
  ${(props) => (props.$step === 2 ? FontStyles.buttonGilroyType2 : FontStyles.bodyMiddleGilroy)};
  margin-bottom: 35px;
  color: ${({ theme }) => theme.palette.greys.darkestGrey};
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    ${FontStyles.body2};
  }
`;

export const InfoText = styled.div`
  ${FontStyles.bodyMiddleGilroy};
  color: ${({ theme }) => theme.palette.primary.black};
  margin-bottom: 35px;
  text-align: center;
`;

export const BlueText = styled.span`
  color: ${({ theme }) => theme.palette.primary.blue};
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`;

export const CustomLargeButton = styled(LargeButton)<{ $step: number }>`
  width: 220px;
  height: 50px;
  text-align: center;
  padding: 15px 30px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: unset;
    height: unset;
    padding: 10px 20px;
    margin-bottom: ${(props) => (props.$step === 2 ? '53px' : '0')};
  }
`;

export const SmsAuthImage = styled.img`
  display: flex;
  outline: none;
  border: none;
  box-sizing: border-box;
  width: 100%;
  object-fit: contain;
  margin-bottom: 24px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const OtpInputContainer = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  border-radius: 10px;
  padding: 15px 20px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid transparent;
  transition: border ease-in-out 300ms;
  box-sizing: border-box;
  height: 70px;
  max-height: 70px;
  width: 250px;

  &:focus-within {
    border: 1px solid ${(props) => props.theme.palette.primary.blue};
  }
`;

export const OtpInputField = styled.input`
  ${FontStyles.bodyMiddleGilroy};
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  color: ${(props) => props.theme.palette.primary.black};
  border: none;
  display: flex;
  align-items: center;
  flex: 1;
  transition: color ease-in-out 300ms;
  box-sizing: border-box;
  width: 100%;
  text-align: center;

  &:focus {
    color: ${(props) => props.theme.palette.primary.blue};
    outline: none;
  }

  &:disabled {
    color: ${(props) => props.theme.palette.greys.darkestGrey};
  }
`;

export const Label = styled.div<{ $step: number }>`
  ${(props) => (props.$step === 2 ? FontStyles.h5 : FontStyles.buttonGilroyType3)};
  color: ${(props) => props.theme.palette.primary.black};
  margin-bottom: 10px;
`;

export const ProblemRow = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 5px;
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;

export const ProblemLink = styled.button`
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  ${FontStyles.buttonGilroyType3};
  color: ${(props) => props.theme.palette.primary.blue};
  transition: 300ms ease-in-out;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.palette.primary.black};
  }

  &:disabled {
    transition: unset;
    color: ${(props) => props.theme.palette.greys.darkestGrey};
    cursor: initial;
    &:hover {
      color: ${(props) => props.theme.palette.greys.darkestGrey};
    }
  }
`;

export const MobilePhoneRow = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 60px;
`;

export const NumberTextInput = styled(TextInput)`
  width: 100%;
  ${FontStyles.bodyMiddleGilroy};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 40px;
    ${FontStyles.body2};
    ${Field} {
      ${FontStyles.body2};
    }
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.bodyMiddleGilroy};
    ${Field} {
      ${FontStyles.bodyMiddleGilroy};
    }
    height: 50px;
  }
`;

export const CustomIconOnboardingCheck = styled(IconOnboardingCheck)`
  width: 17px;
  height: 15px;
`;

export const CloseButtonSmall = styled.button<{ $step: number }>`
  border-radius: 50%;
  min-height: 44px;
  width: 44px;
  color: ${(props) => props.theme.palette.primary.black};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 15px;
  top: 15px;

  &:hover {
    cursor: pointer;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    color: ${(props) => props.theme.palette.greys.darkGrey};
    border: 1px solid ${(props) => props.theme.palette.greys.darkGrey};
    & svg {
      margin-left: -2px;
    }
  }
`;

export const SupportFeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 5px;
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  margin: 25px 0 30px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin: 25px 0 0 0;
  }
`;
