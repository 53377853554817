import styled from 'styled-components';

import {
  useGetCorporate,
  useSendEmailVerificationCodeCorporateRegistration,
} from '@api/Corporate/corporateApi';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { IconVerifyMail } from '@constants/icons';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import Modal from '@components/Modal/Modal';

interface Props {
  updatedMail?: string;
}

const EmailModal = ({ updatedMail }: Props) => {
  const closeModal = useBoundStore((state) => state.closeModal);

  const { handleHTTPErrors } = useHTTPErrorHandler();

  const { data: corporateData, error } = useGetCorporate();

  const rootUserEmail = corporateData?.rootUser?.email ?? '-';

  const { mutate: emailVerificationCodeMutation } =
    useSendEmailVerificationCodeCorporateRegistration();

  const handleResendButtonClick = () => {
    const email = corporateData?.rootUser?.email;

    if (!email) {
      closeModal();
      return;
    }

    emailVerificationCodeMutation(email, {
      onError: (error) => {
        handleHTTPErrors([error]);
      },
    });

    closeModal();
  };

  const handleContactSupportButtonClick = () => {
    window.HubSpotConversations.widget.load();
    window.HubSpotConversations.widget.open();
    closeModal();
  };

  return (
    <CustomModal>
      <Icon>
        <IconVerifyMail />
      </Icon>
      <Headline>Verify your email</Headline>
      <GrayText>We&apos;ve sent a verification link to your email address:</GrayText>
      {error ? (
        <EmailError>{error.message}</EmailError>
      ) : (
        <Email>{updatedMail ? updatedMail : rootUserEmail}</Email>
      )}
      <Text>This email may take a few minutes to arrive.</Text>
      <LowerText>Didn&apos;t get one?</LowerText>
      <LinkWrapper>
        <BlueButton onClick={handleResendButtonClick}>Resend</BlueButton>
        or
        <BlueButton onClick={handleContactSupportButtonClick}>Contact our support team</BlueButton>
      </LinkWrapper>
    </CustomModal>
  );
};

export default EmailModal;

const CustomModal = styled(Modal)`
  background: #fff
    linear-gradient(
      174deg,
      #f3f3f9 31.37%,
      #fff 80.73%,
      rgba(255, 255, 255, 0) 92.5%,
      rgba(255, 255, 255, 0) 92.51%
    );

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: 455px;
    height: 530px;
    padding: unset;
  }
`;

const Icon = styled.div`
  margin-top: 55px;
  display: flex;
  height: 76px;
  width: 76px;
  border-radius: 50%;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  box-shadow:
    -3.8px -3.8px 9.5px 0 #fff,
    -5.067px -5.067px 12.667px 0 #fff,
    3.8px 3.8px 9.5px 0 rgba(123, 123, 138, 0.08),
    5.067px 5.067px 12.667px 0 rgba(174, 174, 192, 0.2);

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 98.5px;
  }
`;

const Headline = styled.p`
  margin-top: 20px;
  ${FontStyles.h4};
`;

const GrayText = styled.p`
  margin-top: 35px;
  ${FontStyles.bodyMiddleGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;

const Email = styled.p`
  ${FontStyles.buttonGilroyType3};
  text-align: center;
  color: ${(props) => props.theme.palette.primary.black};
`;

const EmailError = styled.p`
  ${FontStyles.bodyMiddleGilroy};
  color: ${(props) => props.theme.palette.negative.contrastRed};
`;

const Text = styled(GrayText)`
  color: ${(props) => props.theme.palette.primary.black};
`;

const LowerText = styled.p`
  margin-top: 35px;
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.primary.black};
`;

const BlueButton = styled.button`
  ${FontStyles.bodyMiddleGilroy};
  color: ${(props) => props.theme.palette.primary.blue};
  cursor: pointer;
  transition: color 300ms ease-in-out;

  &:hover {
    color: ${(props) => props.theme.palette.primary.black};
  }
`;

const LinkWrapper = styled.div`
  ${FontStyles.bodySmallGilroy};
  display: flex;
  gap: 3px;
  margin-bottom: 25px;
`;
