import React, { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useInitiateLostPassword } from '@api/Password/passwordApi';

import { useBoundStore } from '@stores/BoundStore';

import { FormValidation } from '@shared/formValidation';

import { IconOnboardingCheck } from '@constants/icons';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import Toast from '@elements/Toast/Toast';

import {
  Container,
  CustomCaptcha,
  CustomLargeButton,
  CustomStepNavigation,
  CustomTextInput,
  EmailErrorContainer,
  FormWrapper,
  Label,
  Subtitle,
  Title,
  TitleWrapper,
  Wrapper,
} from './OnboardingRestorePasswordEnterMail.styles';

interface ValidatedEmail {
  email: string;
  valid: boolean;
}

const defaultValidatedEmail: ValidatedEmail = {
  email: '',
  valid: true,
};

const OnboardingRestorePasswordEnterMail = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const setLostPasswordMail = useBoundStore((state) => state.setLostPasswordMail);

  const [captchaValidationResult, setCaptchaValidationResult] = useState<CloudflareCaptcha>({
    captchaToken: null,
    idempotencyRef: null,
    isValid: false,
  });

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [email, setEmail] = useState<ValidatedEmail>(defaultValidatedEmail);

  const { mutate: initiateLostPassword } = useInitiateLostPassword(captchaValidationResult);

  const errorHandling = useMemo(
    () => ({
      409: (error: unknown) => {
        const { errorCode } = error as { errorCode: string };
        if (errorCode === 'MULTIPLE_ACCOUNTS_FOUND') {
          toast.error(
            <Toast
              title="General Error"
              message={t('multipleAccountsFound')}
            />
          );
        } else if (errorCode === 'ACCOUNT_NOT_FOUND') {
          toast.success(
            <Toast
              title="Restore Password"
              message={`${t('accountNotFound')}${email.email} ${t('withFurtherInstructions')}`}
            />
          );
        }
      },
    }),
    [email.email, t]
  );

  const { handleHTTPErrors } = useHTTPErrorHandler(errorHandling);

  const isEmailValid = (email: string) => {
    return FormValidation.RegEx.EMAIL.test(email);
  };

  const handleSubmit = () => {
    if (submitting) return;

    setSubmitting(true);

    initiateLostPassword(email.email, {
      onSuccess() {
        setLostPasswordMail(email.email);
        navigate('2', { state: { from: location.pathname } });
      },
      onError: (error) => {
        handleHTTPErrors([error]);
        setSubmitting(false);
      },
    });
  };

  return (
    <FormWrapper>
      <CustomStepNavigation
        to="/login"
        text="Return to Log In"
      />
      <Container>
        <TitleWrapper>
          <Title>Restore password</Title>
          <Subtitle>
            Please Proceed with the Following Steps to Reset/ Restore Your Password:
          </Subtitle>
        </TitleWrapper>
        <Wrapper>
          <Label>{t('email')}</Label>
          <CustomTextInput
            inputMode="email"
            placeholder={t('email')}
            icon={email.valid && email.email.length > 0 && <IconOnboardingCheck />}
            value={email.email}
            valid={email.valid}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setEmail({
                email: event.target.value,
                valid: isEmailValid(event.target.value),
              });
            }}
          />
        </Wrapper>
        <EmailErrorContainer $visible={!email.valid}>
          {email.email !== ''
            ? 'Please enter a valid email address.'
            : 'Please enter your email address.'}
        </EmailErrorContainer>
        <CustomCaptcha onValidationSuccess={setCaptchaValidationResult} />
        <CustomLargeButton
          disabled={!email.valid || !email.email || submitting || !captchaValidationResult.isValid}
          onClick={handleSubmit}
          text="Send"
          variant="blueWhite"
        />
      </Container>
    </FormWrapper>
  );
};

export default OnboardingRestorePasswordEnterMail;
