import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { ImageCheck, MobileImageCheck } from '@constants/images';

import Contact from '@components/Contact/Contact';
import ModalNew from '@components/Modal/ModalNew';

import LargeButton from '@elements/LargeButton/LargeButton';

export const DeleteContactModal = styled(ModalNew)`
  padding: 0 20px 0 20px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0 40px 0 40px;
  }
`;

export const InfoContainer = styled.div`
  padding: 20px 0 8px 0;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.greys.textGrey};
  & span {
    ${FontStyles.h6};
    padding-left: 8px;
  }
`;
export const CheckedLayer = styled(ImageCheck)`
  display: none;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: initial;
  }
`;

export const MobileCheckedLayer = styled(MobileImageCheck)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const ContactWrapper = styled.div<{ $confirm: boolean }>`
  position: relative;
  width: 100%;
  max-width: 290px;
  margin-top: ${({ $confirm }) => ($confirm ? '66px' : 0)};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 470px;
    margin-top: 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  min-width: 301px;
  gap: 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    min-width: 470px;
    gap: 50px;
  }
`;

export const Content = styled.div<{ $confirmed: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  overflow: hidden;
  padding: ${({ $confirmed }) => ($confirmed ? '132px 17px 260px 17px' : '150px 22px 272px 22px')};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ $confirmed }) => ($confirmed ? '0px 45px 15px 45px' : '0px 45px 15px 45px')};
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin-top: 85px;
`;
export const Header = styled.div`
  ${FontStyles.h4};
  text-align: left;
  padding: 16px 0;
`;

export const HeadLine = styled.h3`
  ${FontStyles.h3};
  color: ${({ theme }) => theme.palette.primary.black};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    ${FontStyles.h2};
  }
`;

export const SubHeadLine = styled.h6`
  ${FontStyles.h6};
  color: ${({ theme }) => theme.palette.greys.darkestGrey};
  margin-top: 10px;
  text-align: center;

  & > :first-child {
    display: none;
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    ${FontStyles.bodyMiddleGilroy}
    display: flex;
    gap: 8px;
    align-items: center;
    color: ${({ theme }) => theme.palette.primary.black};

    & > :first-child {
      display: flex;
    }
  }
`;

export const ButtonContainer = styled.div<{ $confirmed: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 10px;
  margin: 16px 0 16px 0;
`;

export const BlueButton = styled(LargeButton)`
  &.blueButton {
    min-width: 230px;
    flex: 1;
  }
`;

export const DeletedContact = styled(Contact)<{ $confirm: boolean }>`
  box-sizing: border-box;
  width: 100%;
  opacity: ${({ $confirm }) => ($confirm ? 0.15 : 1)};
`;
