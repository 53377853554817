import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetCorporate, useGetKYB, useStartKYB } from '@api/Corporate/corporateApi';
import {
  useCreateManagedAccount,
  useGetAllManagedAccounts,
  useUpgradeManagedAccountToIban,
} from '@api/ManagedAccounts/managedAccountsApi';

import { useBoundStore } from '@stores/BoundStore';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import EmailModal from '@components/EmailModal/EmailModal';
import OTPModal from '@components/OTPModal';

const useOnboardingSteps = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { handleHTTPErrors } = useHTTPErrorHandler();

  const openModal = useBoundStore((state) => state.openModal);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const {
    data: corporateData,
    isError: isGetCorporateError,
    error: getCorporateError,
    isFetching: isGetCorporateFetching,
  } = useGetCorporate();

  const {
    data: kybData,
    isError: isGetKYBError,
    error: getKYBError,
    isFetching: isGetKYBFetching,
  } = useGetKYB();

  const emailVerified = corporateData?.rootUser?.emailVerified ?? false;
  const mobileNumberVerified = corporateData?.rootUser?.mobileNumberVerified ?? false;

  const isKYBInitiatedStatus = ['INITIATED', 'INITIALIZED', 'REJECTED'].includes(
    kybData?.kybStatus
  );
  const isKYBApprovedStatus = kybData?.kybStatus === 'APPROVED' ?? false;
  const isKYBNotStartedStatus = kybData?.kybStatus === 'NOT_STARTED' ?? false;

  const {
    data: managedAccounts,
    isError: isGetAllManagedAccountsError,
    error: managedAccountsError,
    isFetching: isGetAllManagedAccountsFetching,
  } = useGetAllManagedAccounts(isKYBApprovedStatus);

  const { mutate: startCorporateKybMutation } = useStartKYB();
  const { mutate: createManagedAccountMutation } = useCreateManagedAccount();
  const { mutate: upgradeManagedAccountToIbanMutation } = useUpgradeManagedAccountToIban();

  const userHasManagedAccounts = (managedAccounts?.count ?? 0) > 0;

  const isLoading = isGetCorporateFetching || isGetKYBFetching || isGetAllManagedAccountsFetching;

  useEffect(() => {
    const errorsToHandle = [];

    if (isGetCorporateError && getCorporateError) {
      errorsToHandle.push(getCorporateError);
    }

    if (isGetKYBError && getKYBError) {
      errorsToHandle.push(getKYBError);
    }

    if (isGetAllManagedAccountsError && managedAccountsError) {
      errorsToHandle.push(managedAccountsError);
    }

    if (errorsToHandle.length > 0) {
      handleHTTPErrors(errorsToHandle);
    }
  }, [
    getCorporateError,
    getKYBError,
    handleHTTPErrors,
    isGetAllManagedAccountsError,
    isGetCorporateError,
    isGetKYBError,
    managedAccountsError,
  ]);

  const handleButtonClick = () => {
    if (submitting) return;

    setSubmitting(true);

    if (!emailVerified) {
      openModal(<EmailModal />);
      setSubmitting(false);
      return;
    }

    if (!mobileNumberVerified) {
      openModal(<OTPModal />);
      setSubmitting(false);
      return;
    }

    if (!isKYBApprovedStatus) {
      startCorporateKybMutation(undefined, {
        onSuccess: (data) => {
          const { reference } = data;
          navigate('/kyb', { state: { kybRef: reference } });
          setSubmitting(false);
        },
        onError: (error) => {
          handleHTTPErrors([error]);
          setSubmitting(false);
        },
      });
      return;
    }

    if (isKYBApprovedStatus && !userHasManagedAccounts) {
      const idempotency = crypto.randomUUID();

      const data: ManagedAccountCreationData = {
        friendlyName: 'main-account',
        currency: 'EUR',
        idempotency,
      };

      createManagedAccountMutation(data, {
        onSuccess: (data) => {
          const { id, state } = data;
          if (state.state === 'ACTIVE') {
            upgradeManagedAccountToIbanMutation(id, {
              onSuccess: async () => {
                await queryClient.invalidateQueries({
                  queryKey: ['getAllManagedAccounts'],
                  exact: true,
                });
                setSubmitting(false);
              },
              onError: (error) => {
                handleHTTPErrors([error]);
                setSubmitting(false);
              },
            });
          }
        },
        onError: (error) => {
          handleHTTPErrors([error]);
          setSubmitting(false);
        },
      });
    }
  };

  return {
    isLoading,
    submitting,
    emailVerified,
    mobileNumberVerified,
    isKYBInitiatedStatus,
    isKYBApprovedStatus,
    isKYBNotStartedStatus,
    userHasManagedAccounts,
    handleButtonClick,
  };
};

export default useOnboardingSteps;
