import { formatNumeral } from 'cleave-zen';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { useGetAllManagedAccounts } from '@api/ManagedAccounts/managedAccountsApi';

import { useBoundStore } from '@stores/BoundStore';

import { Commons } from '@shared/functions';

import { IconEUR } from '@constants/icons';

import useMediaQuery from '@hooks/useMediaQuery';
import { amountValidate, descriptionValidate } from '@hooks/useValidation';

import BalanceBox from '@components/BalanceBox/BalanceBox';
import FormNavigation from '@components/FormNavigation/FormNavigation';
import TransactionConfirmation from '@components/transaction/TransactionConfirmation';
import { convertNumberToString } from '@components/transaction/halper';

import BlueButton from '@elements/BlueButton/BlueButton';
import IbanInput from '@elements/input/IbanInput/IbanInput';
import RecipientInput from '@elements/input/RecipientInput/RecipientInput';
// import SelectIban from './SelectIban';
import TextFieldComponent from '@elements/input/TextField/TextFieldComponent';

import {
  EndAdornment,
  NoPaddingTransactionFormBottom,
  TransactionFormContentContainer,
  TransactionFormContentWrapper,
  TransactionFormDivider,
  TransactionFormFieldWrapper,
  TransactionFormFooter,
  TransactionFormMiddle,
  TransactionFormMiddleSection,
  TransactionFormRoleContainer,
  TransactionFormRoleMessage,
  TransactionFormTop,
} from './TransactionFormStyles';

interface Props {
  currentIndex: number;
  setCurrentIndex: Dispatch<SetStateAction<number>>;
}

const TransactionForm = ({ currentIndex, setCurrentIndex }: Props) => {
  const isTablet = useMediaQuery('(min-width:768px)');
  const isLaptop = useMediaQuery('(min-width:1200px)');

  const openModal = useBoundStore((state) => state.openModal);
  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);
  const fullAccessUser = useBoundStore((state) => state.fullAccessUser);

  const selectedContact = useBoundStore((state) => state.selectedContact);
  const setSelectedContact = useBoundStore((state) => state.setSelectedContact);
  const amount = useBoundStore((state) => state.amount);
  const setAmount = useBoundStore((state) => state.setAmount);

  const defaultTransactionRecipient = useBoundStore((state) => state.defaultTransactionRecipient);
  const transactionRecipient = useBoundStore((state) => state.transactionRecipient);
  const setTransactionRecipient = useBoundStore((state) => state.setTransactionRecipient);
  const iban = useBoundStore((state) => state.iban);
  const setIban = useBoundStore((state) => state.setIban);
  const setBic = useBoundStore((state) => state.setBic);
  const setAccountNumber = useBoundStore((state) => state.setAccountNumber);
  const setRoutingNumber = useBoundStore((state) => state.setRoutingNumber);
  const description = useBoundStore((state) => state.description);
  const setDescription = useBoundStore((state) => state.setDescription);
  const setDateOfTransactionExecution = useBoundStore(
    (state) => state.setDateOfTransactionExecution
  );
  // const tag = useBoundStore((state) => state.tag);
  const setTag = useBoundStore((state) => state.setTag);
  const scheduledFormMode = useBoundStore((state) => state.scheduledFormMode);
  const selectedTransaction = useBoundStore((state) => state.selectedTransaction);
  const setSelectedTransaction = useBoundStore((state) => state.setSelectedTransaction);
  const repeat = useBoundStore((state) => state.repeat);
  const setRepeat = useBoundStore((state) => state.setRepeat);
  const validation = useBoundStore((state) => state.validation);
  const setValidation = useBoundStore((state) => state.setValidation);

  const [proceeded, setProceeded] = useState<boolean>(false);
  // const [recipientValid, setRecipientValid] = useState<boolean>(true);
  const [country, setCountry] = useState<string>('');

  const { data: managedAccountData } = useGetAllManagedAccounts(verifiedKYC);

  const balance = managedAccountData?.accounts?.[0].balances?.availableBalance ?? 0;
  const currency = managedAccountData?.accounts?.[0].currency ?? 'EUR';

  let RECENT_IBAN_LIST;

  // const removeWhitespaces = (input: string): string => {
  //   return input.replace(/\s+/g, '');
  // };

  if (selectedContact?.bankDetails) {
    RECENT_IBAN_LIST = selectedContact.bankDetails.filter((f) => f.iban.startsWith(iban));
  }

  useEffect(() => {
    if (!validation.name && !validation.amount && !validation.iban && !validation.description) {
      setProceeded(true);
    } else {
      setProceeded(false);
    }
  }, [validation]);

  const resetForm = useCallback(() => {
    setSelectedContact(null);
    setSelectedTransaction(null);
    setTag('');
    setCountry('');
    setAccountNumber('');
    setRoutingNumber('');
    setIban('');
    setBic('');
    setAmount('');
    setDescription('');
    setTransactionRecipient(defaultTransactionRecipient);
    setDateOfTransactionExecution(new Date().toLocaleDateString('de-DE'));
  }, [
    defaultTransactionRecipient,
    setAccountNumber,
    setAmount,
    setBic,
    setDateOfTransactionExecution,
    setDescription,
    setIban,
    setRoutingNumber,
    setSelectedContact,
    setSelectedTransaction,
    setTag,
    setTransactionRecipient,
  ]);

  const setFormFromSelectedContact = useCallback(() => {
    if (selectedContact) {
      setTransactionRecipient(selectedContact);
      setTag(selectedContact.tag || '');
      setCountry(selectedContact.country);
      if (selectedContact.bankDetails && selectedContact.bankDetails.length > 0) {
        let selectedBankDetails = selectedContact.bankDetails.find((bankDetail) => bankDetail.main);
        selectedBankDetails = selectedBankDetails || selectedContact.bankDetails[0];
        setIban(selectedBankDetails.iban ?? '');
        setBic(selectedBankDetails.bic ?? '');
      } else {
        setIban('');
        setBic('');
      }
    }
  }, [selectedContact, setBic, setIban, setTag, setTransactionRecipient]);

  const setFormFromSelectedTransaction = useCallback(() => {
    if (selectedTransaction) {
      const transactionRecipient: ContactsWithBankDetails = {
        id: '',
        userId: selectedTransaction.transactions.userId,
        tag: selectedTransaction.transactions.tag,
        name: selectedTransaction.transactions.name,
        address: selectedTransaction.contacts?.address ?? '',
        country: selectedTransaction.contacts?.country ?? '',
        city: selectedTransaction.contacts?.city ?? '',
        postcode: selectedTransaction.contacts?.postcode ?? '',

        profileId: '',
        bankDetails: [],
        createdAt: null,
        updatedAt: null,
      };

      setTransactionRecipient(transactionRecipient);
      setTag(transactionRecipient.tag);
      setCountry(transactionRecipient.country);
      setAccountNumber(selectedTransaction.transactions.accountNumber);
      setRoutingNumber(selectedTransaction.transactions.routingNumber);
      setIban(selectedTransaction.transactions.iban);
      setBic(selectedTransaction.transactions.bicSwift ?? '');
      setAmount(convertNumberToString(selectedTransaction.transactions.amount));
      setDescription(selectedTransaction.transactions.reference);
      setDateOfTransactionExecution(
        new Date(selectedTransaction.transactions.scheduledAt).toLocaleDateString('de-DE')
      );
    }
  }, [
    selectedTransaction,
    setAccountNumber,
    setAmount,
    setBic,
    setDateOfTransactionExecution,
    setDescription,
    setIban,
    setRoutingNumber,
    setTag,
    setTransactionRecipient,
  ]);

  const openConfirmationModal = useCallback(() => {
    if (selectedTransaction && selectedContact) {
      setAmount(convertNumberToString(selectedTransaction.transactions.amount));
      setTransactionRecipient(selectedContact);
      setDescription(selectedTransaction.transactions.reference);
      setBic(selectedTransaction.transactions.bicSwift);
      setCountry(selectedContact.country);
      openModal(<TransactionConfirmation setProceeded={setProceeded} />);
    }
  }, [
    openModal,
    selectedContact,
    selectedTransaction,
    setAmount,
    setBic,
    setDescription,
    setTransactionRecipient,
  ]);

  useEffect(() => {
    setProceeded(false);

    if (verifiedKYC) {
      if (selectedTransaction && selectedContact) {
        openConfirmationModal();
      } else if (selectedContact) {
        setFormFromSelectedContact();
      } else if (selectedTransaction) {
        setFormFromSelectedTransaction();
      } else if (!repeat) {
        resetForm();
      }
    } else {
      resetForm();
    }

    setRepeat(false);
  }, [
    currentIndex,
    defaultTransactionRecipient,
    openModal,
    repeat,
    selectedContact,
    selectedTransaction,
    setAccountNumber,
    setAmount,
    setBic,
    setDescription,
    setIban,
    setRepeat,
    setRoutingNumber,
    setSelectedContact,
    setSelectedTransaction,
    setTag,
    setTransactionRecipient,
    verifiedKYC,
    openConfirmationModal,
    resetForm,
    setFormFromSelectedContact,
    setFormFromSelectedTransaction,
  ]);

  useEffect(() => {
    if (selectedTransaction && scheduledFormMode) {
      setSelectedContact(null);
      if (selectedTransaction.transactions.type === 'SEPA') {
        setCurrentIndex(0);
      } else setCurrentIndex(1);
      setProceeded(false);
    } else {
      setCurrentIndex(0);
    }
  }, [scheduledFormMode, selectedTransaction, setCurrentIndex, setSelectedContact]);

  useEffect(() => {
    setDateOfTransactionExecution(new Date().toLocaleDateString('de-DE'));
  }, [setDateOfTransactionExecution]);

  useEffect(() => {
    setTransactionRecipient((previousRecipient) => ({
      ...previousRecipient,
      country: country,
    }));
  }, [setTransactionRecipient, country]);
  const renderBalanceText = () => {
    return Commons.currencyFormatter(
      balance > 0 ? Commons.formatWeavrAmountToPeanuds(balance) : 0,
      currency
    );
  };

  // const renderScheduledFormButtonText = () => {
  //   return Commons.currencyFormatter(convertStringToNumber(amount), currency);
  // };

  const getBalance = () => {
    return balance > 0 ? Commons.formatWeavrAmountToPeanuds(balance) : 0;
  };

  const handleAmountValidate = (value: string) => {
    const validMessage = amountValidate(value, balance);

    setValidation('amount', validMessage);

    return validMessage;
  };

  const handleDescriptionValidate = (value: string) => {
    const validMessage = descriptionValidate(value);

    setValidation('description', validMessage);

    return validMessage;
  };

  return (
    <TransactionFormContentContainer>
      {isTablet && (
        <TransactionFormTop>
          {/*<SelectIban />*/}
          <BalanceBox balance={getBalance()} />
        </TransactionFormTop>
      )}
      <TransactionFormMiddle>
        <TransactionFormMiddleSection>
          <TransactionFormFieldWrapper>
            <RecipientInput
              setRecipient={setTransactionRecipient}
              recipient={transactionRecipient}
            />
          </TransactionFormFieldWrapper>
          <TransactionFormFieldWrapper>
            <TextFieldComponent
              labelTop="Amount"
              subLabel={!isTablet ? `Balance: ${renderBalanceText()}` : undefined}
              placeholder="0,00"
              value={amount}
              validate={handleAmountValidate}
              onChange={(e) =>
                setAmount(
                  formatNumeral(e.target.value, {
                    numeralPositiveOnly: true,
                    numeralDecimalScale: 2,
                    numeralIntegerScale: 8,
                    delimiter: '.',
                    numeralDecimalMark: ',',
                  })
                )
              }
              icon={
                <EndAdornment>
                  <IconEUR />
                </EndAdornment>
              }
              error={validation.amount}
            />
          </TransactionFormFieldWrapper>
        </TransactionFormMiddleSection>
        <TransactionFormMiddleSection>
          <TransactionFormFieldWrapper>
            <IbanInput
              value={iban}
              setValue={setIban}
              ibanList={RECENT_IBAN_LIST}
            />
          </TransactionFormFieldWrapper>
          <TransactionFormFieldWrapper>
            <TextFieldComponent
              labelTop="Reference"
              placeholder="Description for Transaction"
              value={description}
              validate={handleDescriptionValidate}
              error={validation.description}
              onChange={(e) => setDescription(e.target.value)}
              type="outlined"
            />
          </TransactionFormFieldWrapper>
        </TransactionFormMiddleSection>
      </TransactionFormMiddle>
      <NoPaddingTransactionFormBottom>
        <BlueButton
          onClick={() => {
            openModal(<TransactionConfirmation setProceeded={setProceeded} />);
          }}
          size="large"
          disabled={!proceeded}
        >
          {scheduledFormMode ? 'Pay Now' : 'Proceed with Transaction'}
        </BlueButton>
      </NoPaddingTransactionFormBottom>
      {!fullAccessUser && (
        <TransactionFormFieldWrapper className="description">
          <TransactionFormRoleContainer>
            Your role is defined as:
            <p className="role">Auditor</p>
          </TransactionFormRoleContainer>
          <TransactionFormRoleMessage>
            You are not allowed to facilitate any transactions
          </TransactionFormRoleMessage>
        </TransactionFormFieldWrapper>
      )}
      {isLaptop && scheduledFormMode && <TransactionFormDivider />}
      {scheduledFormMode && (
        <TransactionFormFooter scheduledFormMode={scheduledFormMode}>
          <TransactionFormContentWrapper>
            <FormNavigation
              proceeded={proceeded}
              setProceeded={setProceeded}
            />
            {/* <TransactionFormLargeButtonTransparent
              style={{ minWidth: '190px' }}
              onClick={() => {
                openModal(<SaveChangesModal />);
              }}
            >
              Apply Changes
            </TransactionFormLargeButtonTransparent>*/}
          </TransactionFormContentWrapper>
        </TransactionFormFooter>
      )}
    </TransactionFormContentContainer>
  );
};

export default TransactionForm;
