import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { IconSearch } from '@constants/icons';

const SearchBar = () => {
  const query = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const setSearchQuery = useBoundStore((state) => state.setSearchQuery);

  const handleDebouncedChange = useCallback(
    debounce((value: string) => {
      setSearchQuery(value);
    }, 300),
    [setSearchQuery]
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (query.current !== null) {
      handleDebouncedChange(query.current.value);
      if (event.target.value !== '') {
        document.getElementById('icon')?.classList.add('filled');
      } else {
        document.getElementById('icon')?.classList.remove('filled');
      }
    }
  };

  useEffect(() => {
    return () => {
      // Cleanup debounce on unmount
      handleDebouncedChange.cancel();
    };
  }, [handleDebouncedChange]);

  return (
    <SearchContainer>
      <SearchInput
        type="text"
        placeholder={t('search')}
        ref={query}
        onChange={handleChange}
      />
      <SearchButton
        type="button"
        id="icon"
      >
        <IconSearch />
      </SearchButton>
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  display: flex;
  border: 1px solid ${(props) => props.theme.palette.greys.middleGrey};
  border-radius: 25px;
  flex: 1;
  align-items: center;
  padding: 10px 15px;
  transition: border ease-in-out 300ms;
  box-sizing: border-box;
  width: 100%;
  height: 41px;
  &:hover {
    border: 1px solid ${(props) => props.theme.palette.greys.darkGrey};
  }

  &:focus-within {
    border: 1px solid ${(props) => props.theme.palette.primary.blue};

    & > :last-child {
      transition: color 300ms ease-in-out;
      color: ${(props) => props.theme.palette.primary.blue};
    }
  }
`;

const SearchInput = styled.input`
  all: unset;
  flex: 1;
  ${FontStyles.bodySmallGilroy};
  transition: color ease-in-out 300ms;

  &:focus {
    color: ${(props) => props.theme.palette.primary.blue};
  }
`;

const SearchButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  transition: color 300ms ease-in-out;
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  cursor: pointer;

  &.filled {
    color: ${(props) => props.theme.palette.primary.black};
  }
`;

export default SearchBar;
