import { format } from 'date-fns';

import Avatar from '@components/Avatar/Avatar';

import { Container, List, Name, Since } from './Contact.styles';

interface Props {
  contact: ContactsWithBankDetails;
  className?: string;
}
const Contact = ({ contact }: Props) => {
  const {
    name,
    // tag,
    createdAt,
  } = contact;

  return (
    <Container>
      {' '}
      <Avatar
        src=""
        alt={`Avatar ${name}`}
        text={name ?? 'A'}
        dark={true}
        size="medium"
      />
      <List>
        <Name>{name}</Name>
        {createdAt && (
          <Since>In contact list since: {format(new Date(createdAt), 'dd/MM/yyyy')}</Since>
        )}
        {/*<Tag>{!tag || tag === '' ? 'No hashtag assigned' : tag}</Tag>*/}
      </List>
    </Container>
  );
};

export default Contact;
