import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useAddBankDetails } from '@api/SendMoney/sendMoneyApi';

import { useBoundStore } from '@stores/BoundStore';

import { ibanValidate } from '@hooks/useValidation';

import BlueButton from '@elements/BlueButton/BlueButton';
import TextFieldComponent from '@elements/input/TextField/TextFieldComponent';

interface CustomError {
  response?: {
    data?: {
      message?: string;
    };
  };
}
const IbanForm = () => {
  const { mutate: updateContact, error, data } = useAddBankDetails();
  const selectedContact = useBoundStore((state) => state.selectedContact);
  const setSelectedContact = useBoundStore((state) => state.setSelectedContact);
  const [errorMessage, setErrorMessage] = useState('');
  const [iban, setIban] = useState('');

  const updateContactIban = () => {
    if (selectedContact) {
      const newContact = { contactId: selectedContact.id, iban, bic: '', star: false };

      updateContact(newContact);
    }
  };

  useEffect(() => {
    if (data) {
      if (selectedContact) {
        const newContact = { ...selectedContact };
        newContact.bankDetails = data;

        setSelectedContact(newContact);
      }

      setIban('');
      setErrorMessage('');
    }

    if (error) {
      const axiosError = error as CustomError;
      const message = axiosError.response?.data?.message;
      message && setErrorMessage(message);
    }
  }, [data, error]);

  const handleValidate = (value: string) => {
    const Message = value && ibanValidate(value);
    setErrorMessage(Message);
    return Message;
  };

  return (
    <Container>
      <TextFieldComponent
        labelTop="Add new IBAN"
        placeholder="Add new IBAN"
        value={iban}
        onChange={(e) => setIban(e.target.value)}
        error={errorMessage}
        validate={handleValidate}
      />
      <ButtonWrap>
        <BlueButton
          onClick={updateContactIban}
          size="small"
          disabled={!(iban && !errorMessage)}
        >
          Add to IBAN’s list
        </BlueButton>
      </ButtonWrap>
    </Container>
  );
};
export default IbanForm;

const Container = styled.div`
  transition: background-color 300ms ease-in-out;
  display: flex;
  align-items: end;
  justify-content: start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
`;
const ButtonWrap = styled.div`
  box-sizing: border-box;
`;
