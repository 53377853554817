import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { useDeleteContact } from '@api/SendMoney/sendMoneyApi';

import { useBoundStore } from '@stores/BoundStore';

import { IconInfo } from '@constants/icons';

import {
  ButtonContainer,
  DeleteContactModal,
  Header,
  InfoContainer,
} from '@components/DeleteContactConfirmation/DeleteContactConfirmation.styles';
import ContactItem from '@components/transaction/ContactInformation/ContactItom';

import BlueButton from '@elements/BlueButton/BlueButton';
import BorderButton from '@elements/BorderButtonBlue/BorderButton';
import InfoBox from '@elements/InfoBox/InfoBox';
import Toast from '@elements/Toast/Toast';

interface Props {
  selectedContact: ContactsWithBankDetails;
}

const DeleteContactConfirmation = ({ selectedContact }: Props) => {
  const queryClient = useQueryClient();

  const closeModal = useBoundStore((state) => state.closeModal);
  const setSelectedContact = useBoundStore((state) => state.setSelectedContact);

  const [confirmed, setConfirmed] = useState(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { mutate: deleteContact } = useDeleteContact();

  const handleConfirm = () => {
    if (submitting) return;

    setSubmitting(true);

    deleteContact(selectedContact.id, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['contactData'],
        });
        setSelectedContact(null);
        setConfirmed(true);
        setSubmitting(false);
        toast.success(
          <Toast
            title="Contact removed"
            message="Contact removed successfully."
          />
        );
        closeModal();
      },
      onError: () => {
        setSubmitting(false);
      },
    });
  };

  return (
    <DeleteContactModal showCloseIcon={false}>
      <Header>Remove contact?</Header>
      <ContactItem contact={selectedContact} />
      <InfoContainer>
        <IconInfo />
        <span>Warrning</span>
      </InfoContainer>
      <InfoBox
        infoList={[
          'All saved information related to contact will be lost ( IBANS, IMAGE etc)',
          'You will be able to find transactions related to this contact in analytics etc.',
        ]}
      />

      <ButtonContainer $confirmed={confirmed}>
        <BorderButton
          className="blueButton"
          onClick={() => {
            setConfirmed(false);
            closeModal();
          }}
          size="medium"
        >
          Cansel
        </BorderButton>
        {!confirmed && (
          <BlueButton
            disabled={submitting}
            className="deleteButton"
            onClick={handleConfirm}
            size="medium"
            color="warning"
          >
            Remove Contact
          </BlueButton>
        )}
      </ButtonContainer>
    </DeleteContactModal>
  );
};

export default DeleteContactConfirmation;
