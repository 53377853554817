import styled, { keyframes } from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconRotatingStar } from '@constants/icons';

import ProgressBar from '@components/ProgressBar/ProgressBar';

interface Props {
  topText: string;
  bottomText: string;
  currentStep?: number;
}

const Rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const MAX_STEPS = 6;

export const renderOnboardingStep = (
  type: 'register' | 'login' | 'restore' | 'sms' | 'email' | 'change',
  step?: string
) => {
  console.log('step', step);
  const stepNumber = step && step.split('/')[0];
  if (type === 'register' && stepNumber === '6') {
    return (
      <OnboardingStep
        topText="Final Step"
        bottomText="Set Password"
        currentStep={6}
      />
    );
  } else if (type === 'register' && stepNumber) {
    return (
      <OnboardingStep
        topText={`Step #${stepNumber}`}
        bottomText={
          stepNumber === '6'
            ? 'Enter your code'
            : stepNumber === '5'
              ? 'Terms & Conditions'
              : 'Enter Info'
        }
        currentStep={parseInt(stepNumber)}
      />
    );
  } else if (type === 'change') {
    return (
      <OnboardingStep
        topText="Change Mail"
        bottomText="Enter your Mail"
        currentStep={6}
      />
    );
  } else {
    return (
      <OnboardingStep
        topText="Your Financial Toolkit"
        bottomText="Payments Simplified"
      />
    );
  }
};

const OnboardingStep = ({ topText, bottomText, currentStep }: Props) => {
  let progress;
  if (currentStep) {
    progress = (100 / MAX_STEPS) * currentStep;
  }
  return (
    <Container>
      <Top>
        <TextWrapper>
          <TopText>{topText}</TopText>
          <BottomText>{bottomText}</BottomText>
        </TextWrapper>
        <Rotated />
      </Top>
      {progress && <ProgressBar progress={progress} />}
    </Container>
  );
};

export default OnboardingStep;

const Container = styled.div`
  display: none;
  background-color: ${(props) => props.theme.palette.greys.middleGrey};
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  position: absolute;
  z-index: 5;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: flex;
    max-width: 180px;
    width: 180px;
    height: 94px;
    border-radius: 29px;
    padding: 20px 20px 15px 20px;
    gap: 2px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    max-width: 210px;
    width: 210px;
    height: 110px;
    border-radius: 35px;
    padding: 25px 25px 15px 25px;
    gap: 9px;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TextWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  max-width: 120px;
`;

const TopText = styled.p`
  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.h6};
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.h5};
  }
`;

const BottomText = styled.p`
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  ${FontStyles.body2};
  white-space: nowrap;
`;

const Rotated = styled(IconRotatingStar)`
  animation: ${Rotation} 20s linear infinite;
`;
